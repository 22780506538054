export const BINGO_COLORS = [
	{
		light: "#29FFF2",
		dark: "#002F66"
	},
	{
		light: "#FCE48F",
		dark: "#D2A400"
	},
	{
		light: "#FF29C4",
		dark: "#640066"
	},
	{
		light: "#37FFC3",
		dark: "#008079"
	},
	{
		light: "#FF8181",
		dark: "#BC0303"
	},
	{
		light: "#29FFF2",
		dark: "#002F66"
	},
	{
		light: "#FCE48F",
		dark: "#D2A400"
	},
	{
		light: "#FF29C4",
		dark: "#640066"
	},
	{
		light: "#37FFC3",
		dark: "#008079"
	},
	{
		light: "#FF8181",
		dark: "#BC0303"
	}
];
